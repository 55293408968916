import React from "react"
import { graphql } from "gatsby"
import Kroger from "/content/assets/KrogerRewards.png"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Support = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Support</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="support-hero">
            <div class="hero-body">
            </div>
      </section>
      <main>
          <div class="has-text-centered">
          <h1>Supporting the KEEP Foundation</h1>
          <p>The KEEP Foundation is a nonprofit 501(c)(3). There are many ways to support the foundation:</p>
          <h3>Mailed Donations</h3>
          <p>Donations are tax deductable.</p>
          <p> P.O. Box 54972<br />Lexington, KY 40555</p>
          <hr />
          <h3>Donate Online</h3>
          <p>You can donate online through our paypal portal.</p>
          
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="6WTKAUDVZ3SKG" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          <hr />
          <h3>Seattle Slew Specialty License Plate</h3>
          <p>The KEEP Foundation is now offering a Kentucky special license plate featuring a photo of Kentucky Bred and Triple Crown winner Seattle Slew®</p>
          <p>Learn more about the plate <a href="/license-plate">here</a></p>
          <hr />
          <h3>Kroger Rewards</h3>
          <img src={Kroger} alt="Kroger Rewards" title="Kroger Rewards" />
          </div>
        </main>
    </Layout>
  )
}

export default Support

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`